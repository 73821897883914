import {GetStaticPropsContext, InferGetStaticPropsType} from 'next'
import Hero from '../components/shared/Hero'
import {ContentPageNode} from '../types/content-types'
import ParagraphMapper from '../components/ParagraphMapper'
import {getTranslations} from '../lib/get-dictionary-items'
import {drupal} from '../lib/drupal'
import {Layout} from '../components/layout'
import {AdditionalContent} from '../types/api-types'
import {localeCountryMap} from '../constants'
import {useRouter} from 'next/router'
import {
  localizedCacheFileExists,
  MENUS_CACHEFILENAME,
} from '../lib/cacheFileHelper'
import {SharedDataStrategy} from '../lib/cacheStrategies/sharedDataStrategy'
import {SharedDataFileSystem} from '../lib/cacheStrategies/sharedDataStrategy.filesystem'
import {SharedDataRealtime} from '../lib/cacheStrategies/sharedDataStrategy.realtime'
import {SharedDataRemoteCache} from '../lib/cacheStrategies/sharedDataStrategy.remoteCache'
import {getDictionaryFromRedis} from '../lib/redis'

//
export type Error404Props = InferGetStaticPropsType<typeof getStaticProps>

const Custom404 = ({menus, resource, additionalContent}: Error404Props) => {
  const router = useRouter()
  const country =
    localeCountryMap[
      (router.locale?.toLowerCase() as
        | keyof typeof localeCountryMap
        | undefined) ?? 'nl-be'
    ]
  return (
    <Layout menus={menus ?? null} resource={resource}>
      <Hero page={resource} />
      {resource.field_paragraphs?.map(paragraph => (
        <ParagraphMapper
          key={paragraph.id}
          paragraph={paragraph}
          additionalContent={additionalContent}
          country={country}
          resource={resource}
          />
      ))}
    </Layout>
  )
}

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const locale =
    context.locale === 'default' ? 'nl-be' : context.locale?.toLowerCase()
  let sharedDataStrategy: SharedDataStrategy
  if (process.env.NODE_ENV !== 'production') {
    // npm run dev
    sharedDataStrategy = new SharedDataRealtime()
  } else if (
    await localizedCacheFileExists(MENUS_CACHEFILENAME, locale ?? 'nl-be')
  ) {
    // buildtime OR localhost
    sharedDataStrategy = new SharedDataRealtime()
  } else if (context.preview) {
    // preview OR runtime
    sharedDataStrategy = new SharedDataRealtime()
  } else {
    sharedDataStrategy = new SharedDataRealtime()
  }
  const country =
    localeCountryMap[
      (locale as keyof typeof localeCountryMap | undefined) ?? 'nl-be'
    ]
  const error404PageResponse = await drupal.getResourceCollection(
    'group_content--site-group_node-404_page_not_found',
    {
      params: {
        include:
          'entity_id,entity_id.field_paragraphs,entity_id.field_media.field_media_image,entity_id.field_paragraphs.field_content_block.field_media.field_media_image,entity_id.field_paragraphs.field_content_block.field_media.field_media_image,entity_id.field_paragraphs.field_content_block.field_document.field_media_file,entity_id.field_paragraphs.field_teaser.field_media.field_media_image',
        'filter[entity_id.langcode]': locale,
        'filter[gid.field_machine_name]': country,
      },
      locale: locale!,
      defaultLocale: process.env.DEFAULTLOCALE!,
      withAuth: true,
    },
  )
  let additionalContent: AdditionalContent = {}
  const resource = error404PageResponse?.[0]
    ?.entity_id as unknown as ContentPageNode
  const [menus, _] = await Promise.all([
    sharedDataStrategy.getMenu({...context, locale}),
    getTranslations(),
  ])
  const environment = process.env.APP_ENV

  return {
    props: {
      messages: await getDictionaryFromRedis(context.locale!),
      ...menus,
      resource,
      environment,
      additionalContent,
    },
    revalidate: process.env.APP_ENV === 'prd' ? 1800 : 60,
  }
}

export default Custom404
