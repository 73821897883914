import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import MediaRenderer from './MediaRenderer'
import GenericLink from './Link'
import RichText from './RichText'
import {ContentPageNode} from '../../types/content-types'

type Props = {
  page: ContentPageNode
  hideImageOnMobile?: boolean
}

const Hero = ({page, hideImageOnMobile = false}: Props) => {
  if (!page) {
    return null
  }
  const isContentPage = page.type === 'node--content_page'
  return (
    <div className=" justify-content-center">
      <Container fluid={true}>
        <Row>
          <Col
            lg={page.field_media ? '7' : '10'}
            className="c-hero__intro d-flex flex-column align-items-start justify-content-center section-box"
          >
            {page.field_media && !hideImageOnMobile ? (
              isContentPage ? (
                <MediaRenderer
                  priority={true}
                  item={page.field_media}
                  objectFit="cover"
                  wrapperClass="c-hero__image-content-page--mobile w-100 d-lg-none"
                />
              ) : (
                <MediaRenderer
                  priority={true}
                  item={page.field_media}
                  objectFit="contain"
                  wrapperClass="c-hero__image--mobile w-75 d-lg-none ml-auto"
                />
              )
            ) : null}
            <h1 className="c-hero__intro-title text-black font-weight-bolder w-100">
              {page.title}
            </h1>
            {page.field_description ? (
              <RichText
                extraClass="c-hero__intro-description mt-0"
                html={page.field_description.processed}
              />
            ) : null}
            {page.field_button ? (
              <GenericLink
                extraClass={
                  'btn mt-4 py-3 px-4 btn--orange c-hero__button d-flex align-items-center'
                }
                link={page.field_button}
              >
                {page.field_button.title}
              </GenericLink>
            ) : null}
          </Col>
          <Col
            className={`${
              page.field_media
                ? 'c-hero__image d-none d-lg-flex mx-lg-0 p-0 align-items-start offset-xl-1'
                : 'd-none'
            }`}
          >
            {page.field_media ? (
              <MediaRenderer
                priority={true}
                item={page.field_media}
                objectFit="cover"
                layout="fill"
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Hero
